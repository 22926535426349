import React from "react";
import ReactDOM from "react-dom/client";
import Chat from "./Chat";
import {
	createBrowserRouter,
	RouterProvider,
} from "react-router-dom";
import Admin from "./admin";
import DebugPanel from "./debug";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement,
);

const router = createBrowserRouter([
	{
		path: "/",
		element: <Chat />,
	},
	{
		path: "/admin",
		element: <Admin />,
	},
	{
		path:"/debug-panel",
		element: <DebugPanel />,
	}
]);


root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>,
);
