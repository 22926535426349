import React, { useEffect, useState } from "react";
import { LoginContainer, MainContainer, RenderMarkdown } from "./admin";

const db_username = 'admin';
const db_password = 'admin';

const humanizeString = (str: string) => {
   // remove __CADD__ from the string
   str = str.replace(/__CADD__/g, ' ');
   // replace __CENTER__ from the string
   str = str.replace(/__CENTER__/g, ' ');
   // uppercase everything
   str = str.toUpperCase();
   return str;
};

const DebugPanel: React.FC = () => {

   const [username, setUsername] = useState<any>(db_username);
   const [password, setPassword] = useState<any>(db_password);
   const [selected_session_id, setselected_session_id] = useState();
   const [selected_session_messages, setselected_session_messages] = useState<any>({ activate_loop: [] });
   const [slots, setSlots] = useState<any>({});
   const [chats, setChats] = useState<any[]>([]);
   const [currentChat, setCurrentChat] = useState<any>();

   const handleLogin = () => {
      // prompt for username and password
      setUsername(prompt('Enter username:'));
      setPassword(prompt('Enter password:'));
   };

   useEffect(() => {
      fetch('/api/admin/all')
         .then((res) => res.json())
         .then((data) => {
            setChats(data);
         });
   }, []);

   useEffect(() => {
      if (!selected_session_id) return;
      fetch(`/api/admin/debug-panel?session_id=` + selected_session_id,)
         .then((res) => res.json())
         .then((data) => {
            // loop trough data.debugs and parse the data
            const _data = data.debugs.map((debug: any) => {
               // parse the debug data
               try {
                  debug = JSON.parse(debug);
               } catch (error) {
                  // if parsing fails, set the debug data to empty object
                  debug = {};
               }
               return debug;
            });

            setselected_session_messages({
               ...data,
               debugs: _data,
            });
         });
   }, [selected_session_id]);

   return (
      <>
         {username === db_username && password === db_password && <>
            <MainContainer>
               <div className="sidebar">
                  <h2>Chats</h2>
                  <ul>
                     {chats.map((chat, index) => (
                        <li key={chat}
                           style={{
                              color: chat === selected_session_id ? 'red' : 'white',
                              fontWeight: chat === selected_session_id ? 'bold' : 'normal',
                           }}
                           onClick={() => {
                              setselected_session_id(chat);
                           }}
                        >{index + 1} -  <small>{humanizeString(chat)}</small></li>
                     ))}
                  </ul>
               </div>
               <div className="content">
                  <h2 style={{
                     position: 'sticky',
                     top: '0',
                     backgroundColor: '#fff',
                     padding: '2px 10px',
                  }}>Conversation</h2>
                  <pre>
                     <code>
                        {JSON.stringify(selected_session_messages.activate_loop, null, 2)}
                     </code>
                  </pre>
                  <table style={{
                     width: '100%',
                     borderCollapse: 'collapse',
                     borderSpacing: '0',
                     marginTop: '10px',
                  }}
                  className="debug-table"
                  >
                     <tr>
                        <th>Role</th>
                        <th>Content</th>
                     </tr>

                     {selected_session_messages && selected_session_messages.debugs && selected_session_messages.debugs.map((debug: any, index: number) => (
                        <>
                           {debug.map((item: any, idx: number) => (
                              <tr key={index}>
                                 <td>{item.role}</td>
                                 <td>{RenderMarkdown(item.content)}</td>
                              </tr>))}
                        </>

                     ))}
                  </table>
               </div>
            </MainContainer>
         </>}
         {(username !== db_username && password !== db_password) && <LoginContainer>
            <h1>Admin Login</h1>
            <p> Please login to continue</p>
            <button onClick={handleLogin}>Login</button>
         </LoginContainer>}
      </>
   );
};

export default DebugPanel;