import { useEffect, useState } from "react";
import styled from "styled-components";
import remarkDirective from "remark-directive";
import remarkBreaks from "remark-breaks";
import remarkGfm from "remark-gfm";
import rehypeExternalLinks from "rehype-external-links";
import Markdown from "react-markdown";
import { h } from 'hastscript'
import { visit } from 'unist-util-visit';


function myRemarkPlugin() {
   /**
    * @param {import('mdast').Root} tree
    *   Tree.
    * @returns {undefined}
    *   Nothing.
    */
   return (tree: any) => {
      visit(tree, (node) => {
         if (
            node.type === 'containerDirective' ||
            node.type === 'leafDirective' ||
            node.type === 'textDirective'
         ) {

            if (node.name !== 'note') return
            const data = node.data || (node.data = {})

            const tagName = node.type === 'textDirective' ? 'span' : 'div'

            data.hName = tagName
            data.hProperties = h(tagName, node.attributes || {}).properties
         }
      })
   }
}


const db_username = 'admin';
const db_password = 'admin';

export const LoginContainer = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   height: 100vh;
   background-color: #f9f9f9;
   font-family: Arial, sans-serif;
   color: #333;
   button {
      padding: 10px 20px;
      background-color: #333;
      color: #fff;
      border: none;
      cursor: pointer;
      margin-top: 10px;
   }

`;
export const MainContainer = styled.div`
   display: flex;
   font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
   .sidebar{
      width: 350px;
      height: 100vh;
      background-color: #1E1F2B;
      color: #fff;
      padding: 20px;
      overflow-y: auto;
      ul {
         list-style: none;
         padding: 0;
         li {
            padding: 10px;
            cursor: pointer;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:hover {
               background-color: #555;
            }
         }
      }
   }
   .content{
      flex: 1;
      padding: 20px;
      height: 100vh;
      background-color: #f9f9f9;
      overflow-y: auto;
      .debug-table{
         border-collapse: collapse;
         td{
            padding: 10px;
            border-bottom: 1px solid #ddd;
         }
         th{
            padding: 10px;
            border-bottom: 1px solid #ddd;
         }
      }
   }
   .rightbar{
      width:320px;
      height: 100vh;
      padding: 20px;
      overflow-y: auto;
      .slot-table{
         width: 100%;
         border-collapse: collapse;
         td{
            padding: 10px;
            border-bottom: 1px solid #ddd;
         }
         strong{
            font-size: 12px;
         }
      }
   }
`;
let continerWidth = 700;

const ChatBody = styled.div`
    height: calc(100% - 120px);
    background-repeat: repeat;
    margin: 0 auto;
    width: 100%;
    padding-bottom: 80px;
`;
const AIMessage = styled.div`
	background-color: var(--ai-bg);
	color: #000;
	padding: 12px;
	border-radius: 0 12px 12px 12px;
	p{
		margin: 0;
		padding: 0;
		line-height: 1.5;
	}
	a{
		color: #fff !important;
		text-decoration: none;
	}
        `;

const AIChatRow = styled.div`
    padding: 10px;
    display: flex;
    gap: 10px;

    .btn-container.active{
        pointer-events: default;
    }

    .btn-container.inactive{
        pointer-events: none;
        opacity: 0.5;
        cursor: not-allowed;
    }
        
    `;
const HumanChatRow = styled.div`
    padding: 10px;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
   
`;

const ChatName = styled.div`
    padding-bottom: 5px;
    color:#6a6a6a;
    font-size: 12px;
`;


const HumanMessage = styled.div`
    background-color: #FFF;
    padding: 12px;
    border-radius: 12px 0 12px 12px;
    text-align: right;
    p{
        margin: 0;
        padding: 0;
        line-height: 1.5;
     }

	/*  Hide all img with attribute action-btn expect last button */
	img[alt='action-btn']{
		display: none;
	}
	.only-ai{
		display: none;
	}
`;
const ChatIcon = styled.div`
    height: 35px;
    width: 35px;
    /* background: hsl(50deg 100% 50% / 100%); */
    border-radius: 50%;
    display: flex;
    /* align-content: center; */
    align-items: center;
    justify-content: center;
    color: #393939;
    font-weight: 800;
    flex: 0 0 35px;
    img{
        border-radius: 50%;
        width: 100%;
    }
`;

const humanize = (str: string) => {
   return str
      .replace(/_/g, " ")
      .replace(/\w\S*/g, function (txt) {
         return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
};

export const RenderMarkdown = (text: string) => {
   if(!text) return <></>;
   text = text.replaceAll("<br>", "\n\r");

   return <Markdown
      remarkPlugins={[remarkDirective, myRemarkPlugin, remarkBreaks, remarkGfm]}
      rehypePlugins={[rehypeExternalLinks]}
      children={text} />
};


export default function Admin() {
   const [username, setUsername] = useState<any>('');
   const [password, setPassword] = useState<any>('');
   const [selected_session_id, setselected_session_id] = useState();
   const [selected_session_messages, setselected_session_messages] = useState<any[]>([]);
   const [slots, setSlots] = useState<any>({});
   const handleLogin = () => {
      // prompt for username and password
      setUsername(prompt('Enter username:'));
      setPassword(prompt('Enter password:'));
   };
   const [chats, setChats] = useState<any[]>([]);
   useEffect(() => {
      fetch('/api/admin/all')
         .then((res) => res.json())
         .then((data) => {
            setChats(data);
         });
   }, []);
   return (
      <>
         {username === db_username && password === db_password ? (
            <div>
               <MainContainer>
                  <div className="sidebar">
                     <h2>Chats</h2>
                     <ul>
                        {chats.map((chat, index) => (
                           <li key={chat}
                           style={{
                              color: chat === selected_session_id ? 'red' : 'white',
                              fontWeight: chat === selected_session_id ? 'bold' : 'normal',
                           }}
                              onClick={() => {
                                 fetch("/api/chat?session_id=" + chat)
                                    .then((res) => res.json())
                                    .then((data) => {
                                       // reverse the order of messages
                                       data.reverse();
                                       setselected_session_id(chat);
                                       setselected_session_messages(data);
                                    });
                                    fetch("/api/slots?session_id=" + chat)
                                    .then((res) => res.json())
                                    .then((data) => {
                                       setSlots(data);
                                    });
                              }}
                           >{index+1} -  <small>{chat}</small></li>
                        ))}
                     </ul>
                  </div>
                  <div className="content">
                     <h2 style={{
                        position: 'sticky',
                        top: '0',
                        backgroundColor: '#fff',
                        padding: '2px 10px',
                     }}>Conversation</h2>
                     <ChatBody>
                        {selected_session_messages.map((message,) => {
                           if (message.type === "user") {
                              return (
                                 <HumanChatRow key={message.id}>
                                    <HumanMessage>
                                       {RenderMarkdown(message.message)}
                                    </HumanMessage>
                                 </HumanChatRow>
                              );
                           }
                           if (message.type === "system") {
                              return (
                                 <AIChatRow key={message.id}>
                                    <ChatIcon>
                                       <img
                                          src={process.env.PUBLIC_URL + "/logo.svg"}
                                          alt="logo"
                                       />
                                    </ChatIcon>
                                    <div>
                                       <ChatName>Nova</ChatName>
                                       <AIMessage>
                                          {RenderMarkdown(message.message)}
                                       </AIMessage>
                                    </div>
                                 </AIChatRow>
                              );
                           }
                           return (<div> </div>);
                        })}
                     </ChatBody>
                  </div>
                  <div className="rightbar">
                     <h2>Information</h2>
                     <table style={{ width: "100%" }} className="slot-table">
                        <tbody>
                           {Object.keys(slots).map((slot) => (
                              <tr key={slot}>
                                 <td>
                                    <strong>{humanize(slot)}</strong>
                                 </td>
                                 <td>{slots[slot]}</td>
                              </tr>
                           ))}
                        </tbody>
                     </table>
                  </div>
               </MainContainer>
            </div>
         ) : (
            <LoginContainer>
               <h1>Admin Login</h1>
               <p> Please login to continue</p>
               <button onClick={handleLogin}>Login</button>
            </LoginContainer>
         )}
      </>
   );
};
