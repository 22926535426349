import React, { useEffect, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import root from "react-shadow/styled-components";
import Fuse from "fuse.js";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeExternalLinks from "rehype-external-links";
import "./chat.css";
import remarkDirective from "remark-directive";
import { visit } from 'unist-util-visit';
import { h } from 'hastscript'
import remarkBreaks from "remark-breaks";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";

function myRemarkPlugin() {
	/**
	 * @param {import('mdast').Root} tree
	 *   Tree.
	 * @returns {undefined}
	 *   Nothing.
	 */
	return (tree: any) => {
		visit(tree, (node) => {
			if (
				node.type === 'containerDirective' ||
				node.type === 'leafDirective' ||
				node.type === 'textDirective'
			) {

				if (node.name !== 'note') return
				const data = node.data || (node.data = {})

				const tagName = node.type === 'textDirective' ? 'span' : 'div'

				data.hName = tagName
				data.hProperties = h(tagName, node.attributes || {}).properties
			}
		})
	}
}


let continerWidth = 700;

const Container = styled.div`
    :host {
        font-size: initial ;
    }
    margin: 0 auto;
    background: #F5F7F9 url(${process.env.PUBLIC_URL}/bg-pattern.svg);
    min-height: 99vh;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu;
    *{
        box-sizing: border-box;
    }
`;

const ChatHeader = styled.div`
    background-color: var(--primary-color);
    padding: 10px;
    color: #fff;
    height: 50px;
    h1{
        font-size: 20px;
        font-weight: 600;
        line-height: 1px;
		padding-left: 40px;
    }
	img{
		width: 36px;
		display: inline-block;
		position: absolute;
		border-radius: 6px;
		top:5px;
	}
`;

const ChatBody = styled.div`
    height: calc(100% - 120px);
    overflow-y: auto;
    background-repeat: repeat;
    max-width: ${continerWidth}px;
    margin: 0 auto;
    width: 100%;
    padding-bottom: 80px;
`;
const AIMessage = styled.div`
	background-color: var(--ai-bg);
	color: #000;
	padding: 12px;
	border-radius: 0 12px 12px 12px;
	p{
		margin: 0;
		padding: 0;
		line-height: 1.5;
	}
	a{
		color: var(--primary-color) !important;
		text-decoration: none;
	}
        `;

const AIChatRow = styled.div`
    padding: 10px;
    display: flex;
    gap: 10px;

    .btn-container.active{
        pointer-events: default;
    }

    .btn-container.inactive{
        pointer-events: none;
        opacity: 0.5;
        cursor: not-allowed;
    }
        
    `;
const HumanChatRow = styled.div`
    padding: 10px;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
   
`;

const HumanMessage = styled.div`
    background-color: #FFF;
    padding: 12px;
    border-radius: 12px 0 12px 12px;
    text-align: right;
    p{
        margin: 0;
        padding: 0;
        line-height: 1.5;
     }

	/*  Hide all img with attribute action-btn expect last button */
	img[alt='action-btn']{
		display: none;
	}
	.only-ai{
		display: none;
	}
`;
const ChatIcon = styled.div`
    height: 35px;
    width: 35px;
    /* background: hsl(50deg 100% 50% / 100%); */
    border-radius: 50%;
    display: flex;
    /* align-content: center; */
    align-items: center;
    justify-content: center;
    color: #393939;
    font-weight: 800;
    flex: 0 0 35px;
    img{
        border-radius: 50%;
        width: 100%;
    }
`;

const ChatName = styled.div`
    padding-bottom: 5px;
    color:#6a6a6a;
    font-size: 12px;
`;

const ChatOption = styled.div`
    background: #fff;
    border: 1px dashed var(--primary-color);
    display: inline-block;
    padding: 1px 20px;
    border-radius: 4px;
    margin: 3px;
    margin-top: 6px;
    font-size: 14px;
    cursor: pointer;
    line-height: 2;
    &:hover{
        background: var(--ai-bg);
    }
    `;
const QuestionButton = styled.div`
    background: #fff;
    border: 1px solid var(--primary-color);
    display: inline-block;
    padding: 1px 20px;
    border-radius: 4px;
    margin: 3px;
    margin-top: 6px;
    font-size: 14px;
    cursor: pointer;
    line-height: 0;
    &:hover{
        background: var(--ai-bg);
    }
`;
const ChatFooter = styled.div`
    padding: 10px;
    color: #fff;
    height: 70px;
    position: fixed;
    max-width: ${continerWidth}px;
    background: #fff;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 100%;
    border-radius: 8px 8px 0 0;

    > input {
        width: calc(100% - 60px);
        background: #F3F3F3;
        border: 1px solid #d3d3d3;
        padding: 3px;
        border-radius: 4px 0 0 4px;
        padding-left: 12px;
        outline: none;
        font-size: 16px;
        &:hover, &:focus{
            border: 1px solid var(--primary-color);
            outline: none;
        }
    }
    > button{
        width: 60px;
        background: var(--primary-color);
        border: none;
        color: #fff;
        border-radius: 0 4px 4px 0;
        font-size: 14px;
        cursor: pointer;
    }
`;
const ScrollDiv = styled.div`
    height: 10px;
`;

const AITyping = styled.div`
   height: 33px;
    width: 68px;
    background: var(--primary-color);
    border-radius: 12px;
    padding: 5px;
    margin: 12px 50px;

    span{
        background: #fff;
        height: 10px;
        width: 10px;
        display: inline-block;
        border-radius: 50%;
        margin-left: 5px;
        animation: typing 1s infinite;
        animation-timing-function: cubic-bezier(0.67, 0.02, 0.35, 1.19);
    }
    span:nth-child(2){
        animation-delay: 0.1s;
    }
    span:nth-child(3){
        animation-delay: 0.2s;
    }

    @keyframes typing {
        0%{
            transform: translateY(0);
        }
        50%{
            transform: translateY(5px);

        }
        100%{
            transform: translateY(0);
        }
    }
`;

const InitialLoading = styled.div`
   > div {
    width: 90%;
    height: 100px;
    background: linear-gradient(90deg, #f3f3f3, #e0e0e0, #f3f3f3);
    margin: 10px auto;
    border-radius: 12px;
    animation: move_gradient 2s infinite;
    background-size: 400% 400%;

    @keyframes move_gradient {
        0%{
            background-position: 0% 50%;
        }
        50%{
            background-position: 100% 50%;
        }
        100%{
            background-position: 0% 50%;
        }
    }
}
`;

const RenderSelectWrapper = styled.div`
    max-height: 60%;
	height: 60%;
    max-width: ${continerWidth}px;
    width: 98%;
    background: #fff;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    z-index: 100;
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.099);
    border-radius: 12px;
	animation: slideIn 0.5s;
	
    @keyframes slideIn {
        0%{
            transform: translateY(100%) translateX(-50%);
        }
        100%{
            transform: translateY(0) translateX(-50%);
        }
    }
`;

const RenderSelectTitle = styled.div`
    padding: 15px 20px;
    padding-bottom: 10px;
    width: 100%;
    bottom: 0px;
    cursor: text;
    overflow: auto;
    position: sticky;
    top: 0;
    background-color: #F7F7F7;
    z-index: 9999;
    font-weight: 600;
    font-size: 14px;
`;
const SearchContainer = styled.div`
    padding: 10px;
   input[type="search"]{
    display: inline-block;
    border: 1px solid #d9dbdf;
    padding: 10px;
    outline: none;
    font-size: 14px;
    background-color: transparent;
    width: 100%;
    border-radius: 3px;
    &:hover, &:focus{
        border: 1px solid var(--primary-color);
    }
   }
`;
const RenderSelectOption = styled.div<{ active: string }>`
    padding: 15px 20px;
    cursor: pointer;
    font-size: 14px;
    border-top: 1px solid #d9dbdf;
    background-color: ${props => props.active === "active" ? "#c9d5ff" : "#fff"};
    p{
        margin: 0;
        padding: 0;
        line-height: 1.5;
     }
    &:hover{
        background-color: #F7F7F7;
    }
	.no-data{
		padding: 15px;
		background: hsl(50deg 100% 71.62% / 85%);
		margin-top: 12px;
		border-radius: 12px;
	}
	.only-human{
		display: none;
	}
`;
const RenderSelectBox = styled.div`
    overflow-y: auto;
    height: calc(100% - 100px);
    background-color: #fff;
    border-top: 1px solid #d9dbdf;
    border-bottom: 1px solid #d9dbdf;
	display: flex;
    flex-direction: column-reverse;
`;
const RenderDate = styled.input`
	position: fixed;
    width: calc(${continerWidth}px - 20px);
    bottom: 10px;
    background: #f3f3f3;
    z-index: 100;
    height: 50px;
    border: 0;
    /* left: 50%; */
    left: 50%;
    /* bottom: 0; */
    transform: translateX(-50%);
`;

const DateWrapper = styled.div`
	position: fixed;
    width: calc(100vw - 20px);
    bottom: 10px;
    background: #f3f3f3;
    z-index: 100;
    height: 50px;
    border: 0;
    left: 50%;
    transform: translateX(-50%);
`;



const generateRandomId = () => {
	return (
		"__CADD__" +
		Math.random().toString(36).substr(2, 9) +
		"__CENTER__" +
		Math.random().toString(36).substr(2, 9)
	);
};

const addTargetBlank = () => {
  return (tree: any) => {
    visit(tree, 'element', (node: any) => {
      if (node.tagName === 'a' && !node.properties.target) {
        node.properties.target = '_blank';
        node.properties.rel = 'noopener noreferrer'; // Add security best practice
      }
    });
  };
};


const RenderMarkdown = (text: string) => {
	if(!text) return <></>;
	text = text.replaceAll("<br>", "\n\r");

	return <Markdown
		remarkPlugins={[remarkDirective, myRemarkPlugin, remarkBreaks, remarkGfm]}
		rehypePlugins={[rehypeExternalLinks, addTargetBlank]}
		children={text} />
};



const TypeWriter = ({ text, after }: any) => {
	const [currentText, setcurrentText] = useState("");
	const [currentIndex, setcurrentIndex] = useState(0);

	// typing speed 60 wpm if the total words is more than 200, then reduce the increase speed
	let speed = 20;


	if (text.length > 100) {
		speed = 1;
	}

	useEffect(() => {
		if (currentIndex < text.length) {
			const timer = setTimeout(() => {
				setcurrentText((prev: any) => prev + text[currentIndex]);
				setcurrentIndex((prev: any) => prev + 1);
			}, speed);
			return () => clearTimeout(timer);
		} else {
			setTimeout(() => {
				after();
			}, 300);
		}
	}, [currentIndex, text, after]);
	return <span>{RenderMarkdown(currentText)}</span>;
};



const RenderSelect = ({ data, onSelect, title }: any) => {
	const [displayDatas, setdisplayDatas] = useState(data);

	const inputRef = React.useRef<HTMLInputElement>(null);
	const scrollRef = React.useRef<HTMLDivElement>(null);
	const activeRef = React.useRef<HTMLDivElement>(null);
	const [height, setheight] = useState(0);
	const [activeIndex, setactiveIndex] = useState(0);
	const [startDate, setStartDate] = useState(new Date());

	useEffect(() => {
		setTimeout(() => {

			if (inputRef.current) {
				inputRef.current.focus();
			}
		}, 300
		);
	}, []);



	useEffect(() => {
		let activeHeight = activeRef.current?.offsetHeight || 0;
		setheight((activeHeight * displayDatas.length) + 100);
	}, [displayDatas]);

	if (data.length === 0) {
		return <></>
	}

	return (
		<RenderSelectWrapper style={{ height: height }}>
			<RenderSelectTitle>{title}</RenderSelectTitle>
			<RenderSelectBox ref={scrollRef} >
				{displayDatas.map((option: any, index: any) => (
					<RenderSelectOption
						ref={activeIndex === index ? activeRef : null}
						active={activeIndex === index ? "true" : "false"}
						key={index} onClick={() => onSelect(option)}>
						{RenderMarkdown(option.text)}
					</RenderSelectOption>
				))}
			</RenderSelectBox>
			<SearchContainer>
				<input
					type="search"
					placeholder="Search"
					ref={inputRef}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							if (displayDatas.length > 0) {
								onSelect(displayDatas[activeIndex]);
							}
						}
						if (e.key === "ArrowDown") {
							if (activeIndex > 0) {
								setactiveIndex(activeIndex - 1);
							}

						}
						// up
						if (e.key === "ArrowUp") {
							if (activeIndex < displayDatas.length - 1) {
								setactiveIndex(activeIndex + 1);
							}
						}
						if (scrollRef.current) {
							scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
						}
						// activeRef
						if (activeRef.current) {
							activeRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
						}
					}}
					onChange={(e: any) => {
						const fuse = new Fuse(data, {
							keys: ["text"],
							threshold: 0.5,
						});
						if (e.target.value === "") {
							setdisplayDatas(data);
							return;
						}
						const result = fuse.search(e.target.value);
						setdisplayDatas(result.map((r: any) => r.item));

						if (scrollRef.current) {
							scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
						}

						setactiveIndex(0);

					}}
				/>
			</SearchContainer>
		</RenderSelectWrapper>
	);
};

const PromptModel = styled.div`
	position: fixed;
	background: rgba(0, 0, 0, 0.5);
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 100;
	
	@keyframes slideIn {
		0%{
			transform: translateY(100%);
		}
		100%{
			transform: translateY(0);
		}
	}
	.content {
		height: 400px;
		width: 700px;
		background: #fff;
		/* mono font */
		font-family: 'Courier New', Courier, monospace;

		textarea {
			width: 100%;
			height: 100%;
			border: none;
			outline: none;
			padding: 10px;
			font-size: 16px;
		}
		button {
			width: 104%;
			background: var(--primary-color);
			color: #fff;
			border: none;
			padding: 10px;
			font-size: 16px;
			cursor: pointer;
		}
	}
`;

const Chat: React.FC = () => {
	const scrollRef = React.useRef<HTMLDivElement>(null);
	const inputRef = React.useRef<HTMLInputElement>(null);
	const chatBodyRef = React.useRef<HTMLDivElement>(null);
	const [currentInput, setcurrentInput] = useState("");
	const [isloading, setisloading] = useState<boolean>(false);
	const [chats, setchats]: [any, any] = useState([]);
	const [session_id, setsession_id] = useState("");
	const [isOpen] = useState(true);
	const [sessiontoken, setsessiontoken] = useState(1);
	const [isInitialLoading, setisInitialLoading] = useState(true);
	const [disableInput, setdisableInput] = useState(false);
	const [isTyping, setisTyping] = useState(false);
	const [showprompt, setshowprompt] = useState(false);
	const [currentprompt, setcurrentprompt] = useState("");

	useEffect(() => {
// /api/admin/prompt
		fetch("/api/admin/prompt")
			.then((response) => response.json())
			.then((data) => {
				setcurrentprompt(data.prompt);
			});
	}, [showprompt]);


	const sentMessage = (message: string, hidden_data: any) => {
		if (message.trim() === "/prompt") {
			setshowprompt(true);
			return;
		}
		if (message.trim() === "/reset") {
			localStorage.removeItem("session_id");
			window.location.reload();
		}
		const _chats = [...chats, { type: "user", message }];
		setchats(_chats);
		setcurrentInput("");
		setisloading(true);
		scrollIntoView();
		fetch("/api/chat", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				session_id,
				message,
				hidden_data,
			}),
		})
			.then((response) => {
				if (response.ok) {
					setisloading(false);
				}
			})
			.finally(() => {
				setchats([...chats, { type: "user", message }]);
				setcurrentInput("");
				setisloading(false);
				setsessiontoken(sessiontoken + 1);
				scrollIntoView();
			});
	};

	const handleDateChange = (date : Date | null, type: string) => {

		let dateString;
		if(type=="date"){
			// Set the date as a string in 'YYYY-MM-DD' format
			dateString = date ? date.toISOString().split('T')[0] : null;
		} else if(type=="time"){
			// Set the time as a string in 'h:mm aa' format
			dateString = date ? date.toLocaleTimeString() : null;
		} else if(type=="datetime"){
			// Set the date and time as a string in 'YYYY-MM-DD h:mm aa' format
			dateString = date ? date.toISOString().split('T')[0] + ' ' + date.toLocaleTimeString() : null;
		}
		
		sentMessage(dateString || '', {});
	};

	useEffect(() => {
		// check if the session id is already present in the local storage
		const session = localStorage.getItem("session_id");
		try {
			if (session) {
				const { session_id, expiries } = JSON.parse(session);
				const expiriesDate = new Date(expiries);
				// add 1 hour to the current date
				// expiriesDate.setHours(expiriesDate.getHours() + 1);
				const currentDate = new Date();
				if (expiriesDate > currentDate) {
					setsession_id(session_id);
					return;
				}
			}
		} catch (error) { }
		// create a random session id that expires in 1 hour
		const session_id = generateRandomId();
		const expiries = new Date();
		 //expiries.setHours(expiries.getHours() + 12);

		// save the session id in the local storage
		localStorage.setItem(
			"session_id",
			JSON.stringify({ session_id, expiries }),
		);
		setsession_id(session_id);
	}, []);

	useLayoutEffect(() => {
		const timer = setTimeout(() => {
			scrollIntoView();
		}, 500);
		return () => clearTimeout(timer);
	}, []);

	const scrollIntoView = () => {
		if (scrollRef.current) {
			scrollRef.current.scrollIntoView({ behavior: "smooth" });
		}
		setTimeout(() => {
			if (chatBodyRef.current) {
				chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
			}
			if (scrollRef.current) {
				scrollRef.current.scrollIntoView({ behavior: "smooth" });
			}
		}, 700);
	};

	useEffect(() => {
		if (!session_id) {
			return;
		}
		if (!isOpen) {
			return;
		}
		setisTyping(true);

		try {
			setisloading(true);
			fetch("/api/chat?session_id=" + session_id)
				.then((response) => response.json())
				.then((data) => {
					setisInitialLoading(false);
					data = data.reverse();
					let _disable_input = false;
					data = data.map((chat: any) => {
						// if buttons is string, try to parse it
						if (typeof chat.buttons === "string") {
							try {
								// if chat.buttons is doesn't have text key, assign the value to text key
								chat.buttons = JSON.parse(chat.buttons);
							} catch (error) {
								chat.buttons = [];
							}
						}
						// if this is last message, and chat contains disabled input, disable the input
						if (chat.disable_input) {
							_disable_input = true;
						}
						return chat;
					});
					setdisableInput(_disable_input);
					data = data.map((chat: any) => {
						if (chat.buttons && chat.buttons.length > 0) {
							chat.buttons = chat.buttons.map((button: any) => {
								if (typeof button === "string") {
									return { text: button };
								}
								return button;
							});
						}
						return chat;
					});
					setchats(data);
				})
				.finally(() => {
					// scroll into view
					if (scrollRef.current) {
						scrollRef.current.scrollIntoView({ behavior: "smooth" });
					}
					//  focus on the input
					if (inputRef.current) {
						inputRef.current.focus();
					}
					setisloading(false);
				});
		} catch (error) { }
	}, [session_id, isOpen, sessiontoken]);

	return (
		<root.div>
			<style>
				{`
                :host {
                    all: initial;
                    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu;
                }
					@charset "UTF-8";
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  border-color: #ccc;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px;
}
.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
}

.react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
  line-height: initial;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}
.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker-popper {
  z-index: 1;
  line-height: 0;
}
.react-datepicker-popper .react-datepicker__triangle {
  stroke: #aeaeae;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  fill: #f0f0f0;
  color: #f0f0f0;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  fill: #fff;
  color: #fff;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}
.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}
.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 15px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
}

h2.react-datepicker__current-month {
  padding: 0;
  margin: 0;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 2px;
  padding: 0;
  border: none;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  overflow: hidden;
}
.react-datepicker__navigation--previous {
  left: 2px;
}
.react-datepicker__navigation--next {
  right: 2px;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 85px;
}
.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__navigation--years-previous {
  top: 4px;
}
.react-datepicker__navigation--years-upcoming {
  top: -4px;
}
.react-datepicker__navigation:hover *::before {
  border-color: #a6a6a6;
}

.react-datepicker__navigation-icon {
  position: relative;
  top: -1px;
  font-size: 20px;
  width: 0;
}
.react-datepicker__navigation-icon--next {
  left: -2px;
}
.react-datepicker__navigation-icon--next::before {
  transform: rotate(45deg);
  left: -7px;
}
.react-datepicker__navigation-icon--previous {
  right: -2px;
}
.react-datepicker__navigation-icon--previous::before {
  transform: rotate(225deg);
  right: -7px;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  margin: 0.4rem;
  text-align: center;
}
.react-datepicker__year-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 180px;
}
.react-datepicker__year .react-datepicker__year-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}
.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: auto;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time] {
  -moz-appearance: textfield;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 85px;
}
.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -87px;
  top: 0;
}
.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + 1.7rem / 2);
  overflow-y: scroll;
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  box-sizing: content-box;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable:not(.react-datepicker__week-number--selected,
.react-datepicker__week-number--keyboard-selected):hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}
.react-datepicker__week-number--selected {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}
.react-datepicker__week-number--selected:hover {
  background-color: #1d5d90;
}
.react-datepicker__week-number--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #2a87d0;
  color: #fff;
}
.react-datepicker__week-number--keyboard-selected:hover {
  background-color: #1d5d90;
}

.react-datepicker__day-names {
  white-space: nowrap;
  margin-bottom: -8px;
}

.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  font-weight: bold;
}
.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}
.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover,
.react-datepicker__year-text--highlighted:hover {
  background-color: #32be3f;
}
.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1,
.react-datepicker__year-text--highlighted-custom-1 {
  color: magenta;
}
.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2,
.react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}
.react-datepicker__day--holidays,
.react-datepicker__month-text--holidays,
.react-datepicker__quarter-text--holidays,
.react-datepicker__year-text--holidays {
  position: relative;
  border-radius: 0.3rem;
  background-color: #ff6803;
  color: #fff;
}
.react-datepicker__day--holidays .overlay,
.react-datepicker__month-text--holidays .overlay,
.react-datepicker__quarter-text--holidays .overlay,
.react-datepicker__year-text--holidays .overlay {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 4px;
  border-radius: 4px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s ease-in-out;
}
.react-datepicker__day--holidays:hover,
.react-datepicker__month-text--holidays:hover,
.react-datepicker__quarter-text--holidays:hover,
.react-datepicker__year-text--holidays:hover {
  background-color: #cf5300;
}
.react-datepicker__day--holidays:hover .overlay,
.react-datepicker__month-text--holidays:hover .overlay,
.react-datepicker__quarter-text--holidays:hover .overlay,
.react-datepicker__year-text--holidays:hover .overlay {
  visibility: visible;
  opacity: 1;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #1d5d90;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #bad9f1;
  color: rgb(0, 0, 0);
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #1d5d90;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range) {
  background-color: rgba(33, 107, 165, 0.5);
}
.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range), .react-datepicker__year--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__year--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__year--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__year--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range) {
  background-color: #f0f0f0;
  color: #000;
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc;
}
.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  background-color: transparent;
}
.react-datepicker__day--disabled .overlay,
.react-datepicker__month-text--disabled .overlay,
.react-datepicker__quarter-text--disabled .overlay,
.react-datepicker__year-text--disabled .overlay {
  position: absolute;
  bottom: 70%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 4px;
  border-radius: 4px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s ease-in-out;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}
.react-datepicker__input-container .react-datepicker__calendar-icon {
  position: absolute;
  padding: 0.5rem;
  box-sizing: content-box;
}

.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 25px;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
  position: relative;
}
.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}
.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  transform: rotate(135deg);
  right: -16px;
  top: 0;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
}
.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}
.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}
.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #216ba5;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "×";
}
.react-datepicker__close-icon--disabled {
  cursor: default;
}
.react-datepicker__close-icon--disabled::after {
  cursor: default;
  background-color: #ccc;
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}
.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}
@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}
.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.react-datepicker__children-container {
  width: 13.8rem;
  margin: 0.4rem;
  padding-right: 0.2rem;
  padding-left: 0.2rem;
  height: auto;
}

.react-datepicker__aria-live {
  position: absolute;
  clip-path: circle(0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  width: 1px;
  white-space: nowrap;
}

.react-datepicker__calendar-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.125em;
}

.custom_datepicker{
	width: 100%;
	height: 100%;
}

.react-datepicker-wrapper{
	width: 100%;
  	height: 100%;
}
.react-datepicker__input-container{
	width: 100%;
  	height: 100%;
}
            `}
			</style>
			
					{showprompt && (
						<PromptModel>
							<div className="content">
								<textarea
									placeholder="Enter your prompt here"
									autoFocus
									value={currentprompt}
									onChange={(e) => {
										setcurrentprompt(e.target.value);
									}}
								></textarea>
								<button
									onClick={() => {
										setshowprompt(false);
										fetch("/api/admin/prompt", {
											method: "POST",
											headers: {
												"Content-Type": "application/json",
											},
											body: JSON.stringify({
												prompt: currentprompt,
											}),
										});
									}}
								>
									Update
								</button>
							</div>
						</PromptModel>
					)}
			<Container>
				{/* <ChatHeader>
					<img src={process.env.PUBLIC_URL + "/logo.svg"} alt="logo" />
					<h1>
						Chat with Nova</h1>
				</ChatHeader> */}
				<ChatBody ref={chatBodyRef}>
					{isInitialLoading && (
						<InitialLoading>
							<div className=""></div>
							<div className=""></div>
							<div className=""></div>
						</InitialLoading>
					)}
					{!isInitialLoading &&
						chats.length !== 0 &&
						chats.map((chat: any, index: any) => {
							// if it is last message, and chat contains disabled input, disable the input

							if (chat.type === "system") {
								return (
									<AIChatRow key={index}>
										<ChatIcon>
											<img
												src={process.env.PUBLIC_URL + "/logo.svg"}
												alt="logo"
											/>
										</ChatIcon>
										<div>
											<ChatName>Nova</ChatName>
											<AIMessage>
												{chats.length - 1 === index && (
													<TypeWriter
														text={chat.message}
														after={() => {
															setisTyping(false);
															// scroll into view
															if (scrollRef.current) {
																scrollRef.current.scrollIntoView({
																	behavior: "smooth",
																});
															}
															// focus on the input
															if (inputRef.current && !showprompt) {
																inputRef.current.focus();
															}
														}}
													/>
												)}
												{chats.length - 1 !== index && RenderMarkdown(chat.message)}
											</AIMessage>
											{!isTyping && chat.buttons && chat.buttons.length > 0 && chats.length - 1 === index && (
												<div
													className={
														//  active only the last message
														chats.length - 1 === index
															? "active btn-container"
															: "inactive btn-container"
													}
												>
													{chat.buttons.map((option: any, index: any) => (
														<ChatOption
															key={index}
															onClick={() => {
																sentMessage(option.text, {});
															}}
														>
															{option.text}
														</ChatOption>
													))}
												</div>
											)}
											{!isTyping && chats.length - 1 === index &&
												chat.question_type === "buttons" && (
													<>
														{chat.options.map((option: any, index: any) => (
															<QuestionButton
																key={index}
																onClick={() => {
																	sentMessage(option.text, {});
																}}
															>
																{RenderMarkdown(option.text)}
																{/* {option.text} */}
															</QuestionButton>
														))}
													</>
												)}
										</div>
										{!isTyping && chats.length - 1 === index &&
											chat.question_type === "select" && (
												<RenderSelect
													title={chat.message}
													data={chat.options}
													onSelect={({ value, text }: any) =>
														sentMessage(text, { data: value })
													}
												/>
											)}

											{/* Date */}
											{!isTyping && chats.length - 1 === index && chat.question_type === "date" && (
												<DateWrapper>
													<DatePicker className="custom_datepicker"
														onChange={(date) => {
															handleDateChange(date, "date");
														}}
														minDate={new Date()}
														placeholderText="Select a date"
												/>
												</DateWrapper>
												
												// <RenderDate
												// 	type="date"
												// 	className="datepicker-date"
												// 	onChange={(e) => {
												// 		sentMessage(e.target.value, {});
												// 	}}
												// />
											)}
											{/* time */}
											{!isTyping && chats.length - 1 === index && chat.question_type === "time" && (
												<DateWrapper>
													<DatePicker className="custom_datepicker"
														onChange={(date) => {
															handleDateChange(date, "time");
														}}
														showTimeSelect
														showTimeSelectOnly
														timeIntervals={15}
														timeCaption="Time"
														dateFormat="h:mm aa"
														minDate={new Date()}
														placeholderText="Select a time"
													/>
												</DateWrapper>
												// <RenderDate
												// 	type="time"
												// 	onChange={(e) => {
												// 		sentMessage(e.target.value, {});
												// 	}}
												// />
											)}
											{/* datetime */}
											{!isTyping && chats.length - 1 === index && chat.question_type === "datetime" && (
												// <RenderDate
												// 	type="datetime-local"
												// 	onChange={(e) => {
												// 		sentMessage(e.target.value, {});
												// 	}}
												// />
												<DateWrapper>
													<DatePicker className="custom_datepicker"
														onChange={(date) => {
															handleDateChange(date, "datetime");
														}}
														// date and time
														showTimeSelect
														timeFormat="h:mm aa"
														timeIntervals={15}
														dateFormat="MMMM d, yyyy h:mm aa"
														timeCaption="Time"
														placeholderText="Select a date and time"
														minDate={new Date()}
													/>
												</DateWrapper>
											)}
									</AIChatRow>
								);
							} else {
								return (
									<HumanChatRow key={index}>
										<HumanMessage>
											{RenderMarkdown(chat.message)}
										</HumanMessage>
									</HumanChatRow>
								);
							}
						})}
					{isloading && (
						<AITyping>
							<span />
							<span />
							<span />
						</AITyping>
					)}
					<ScrollDiv ref={scrollRef}></ScrollDiv>

				</ChatBody>
				<ChatFooter style={{ display: disableInput ? "none" : "flex" }}>
					
					<input
						type="text"
						placeholder="Type your message here"
						ref={inputRef}
						value={currentInput}
						disabled={disableInput || isTyping}
						onChange={(e) => {
							setcurrentInput(e.target.value);
						}}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								sentMessage(currentInput, {});
							}
						}}
					/>
					<button
						onClick={() => {
							sentMessage(currentInput, {});
						}}
					>
						Send
					</button>
				</ChatFooter>
			</Container>
		</root.div>
	);
};

export default Chat;
